import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditarObjetoPageRoutingModule } from './editar-objeto-routing.module';

import { EditarObjetoPage } from './editar-objeto.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditarObjetoPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [EditarObjetoPage]
})
export class EditarObjetoPageModule {}
