import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-editar-objeto',
  templateUrl: './editar-objeto.page.html',
  styleUrls: ['./editar-objeto.page.scss'],
})
export class EditarObjetoPage implements OnInit {

  ionicForm: FormGroup;
  isSubmitted = false;
  objeto:any = {};
  id:any;

  constructor( public formBuilder: FormBuilder, private _api:ApiService, private route: ActivatedRoute, 
              public alertController:AlertController, public navCtrl:NavController ) { 
    this.route.params.subscribe((params:any)=>{
      this.id = params.id;
      console.log(this.id);
    });
    this.ionicForm = this.formBuilder.group({
      // email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],            
      // password: ['', [Validators.required]],    
      nombre: ['', [Validators.required, Validators.minLength(3)]],      
      modelo: ['',[Validators.maxLength(30)]],
      placas: ['',[Validators.maxLength(30)]],  
    });

  }

  submitForm() {
    this.isSubmitted = true;
    console.log(this.ionicForm.valid);
    if (!this.ionicForm.valid) {
      // console.log('Please provide all the required values!')
      return false;
    } else {
      console.log(this.ionicForm.value);
      this.ionicForm.value.id = this.objeto._id;
      this._api.updateObjeto(this.ionicForm.value).subscribe((data:any)=>{
        console.log(data);
        if( data.status ){
          this.presentAlert("El objeto se actualizó correctamente");
          this.navCtrl.back();
        }else{
          this.presentAlert("Error al actualizar objeto, intentelo maás tarde");
        }
      });
      
    }
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({      
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

  ngOnInit() {
    this.getObjeto();
  }

  getObjeto(){
    this._api.getObjeto({imei:this.id}).subscribe((data:any)=>{
      console.log(data);
      this.objeto = data.data[0];
    });
  }

}
